export const calculateCustomBasePrice = (customLength, customWidth, customHeight) => {
    const reference = 0.01464;
    return ((customLength * customHeight * 2)+ (customHeight * customWidth * 2)) * reference;
}

export const calculateCustomLipPrice = (customLength, customWidth) => {
    const reference = 0.00662;
    return ((customLength * 4 * 4 * 2)+ (customWidth * 4 * 4 * 2)) * reference;
}

export const calculateCustomIntermediatePrice = (customLength, customWidth) => {
    const reference = 0.01464;
    return (customLength * customWidth) * reference;
}