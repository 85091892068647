export default function InputField({title, placeholder, value, onChangeValue}){
    return(
    <div className="opacity-70 flex flex-col justify-evenly">
        <span>{title}</span>
        <div className="flex flex-row items-center space-x-2">
            <input className="w-full rounded-md text-gray-500 outline-none border-sky-800" value={value} onChange={e => onChangeValue(e.currentTarget.value)} type="number" placeholder={placeholder}/>
            <span>cm</span>
        </div>
    </div>
    );
}