import { useRef, useState, Suspense, useEffect } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useGLTF, OrbitControls, ContactShadows, Environment, Stage } from '@react-three/drei'
import { TextureLoader } from 'three'
import { RadioGroup } from '@headlessui/react'
import SelectionGroup from './OptionSelectionGroup'
import Blumenkasten from './Blumenkasten'
import OptionSelectionGroup from './OptionSelectionGroup'
import FormSelectionGroup from './FormSelectionGroup'
import Overview from './Overview'
import { calculateCustomBasePrice, calculateCustomIntermediatePrice, calculateCustomLipPrice } from './functions'
import ExampleImagesPopup from './ExampleImagesPopup'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const types = [
  {
    title: 'Standard Hochbeet',
    length: 160,
    height: 70,
    width: 100
  },
  {
    title: 'Standard Pflanzkasten',
    length: 125,
    height: 60,
    width: 40
  },
  {
    title: 'Eigene Außenmaße',
    custom: true,
    length: 125,
    height: 60,
    width: 40
  }
]

const lips = [
  {
    title: 'Ohne Kante'
  },
  {
    title: 'Mit Kante',
    differentPrice: true
  }
]

const intermediateFloors = [
  {
    title: 'Ohne Zwischenboden'
  },
  {
    title: 'Mit Zwischenboden',
    differentPrice: true
  }
]

const materials = [
  {
    title: '4 mm Stahl'
  },
  {
    title: '3mm Cortenstahl',
    differentPrice: true
  }
]

const sum = (array) => array.reduce((pv, cv) => pv + cv, 0)

export default function App() {
  const [siteIndex, setSiteIndex] = useState(0)
  const [selectedSize, setSelectedSize] = useState(types[1])
  const [selectedUpperLip, setSelectedUpperLip] = useState(lips[0])
  const [selectedIntermediateFloor, setSelectedIntermediateFloor] = useState(intermediateFloors[0])
  const [selectedMaterial, setSelectedMaterial] = useState(materials[0])

  const [openDemoImages, setOpenDemoImages] = useState(false)

  const [customLength, setCustomLength] = useState(null)
  const [customWidth, setCustomWidth] = useState(null)
  const [customHeight, setCustomHeight] = useState(null)

  const [basePrice, setBasePrice] = useState(null)
  const [lipPrice, setLipPrice] = useState(null)
  const [intermediatePrice, setIntermediatePrice] = useState(null)
  const [materialPrice, setMaterialPrice] = useState(null)

  const [totalPrice, setTotalPrice] = useState(null)

  useEffect(() => {
    if (customLength && customHeight && customWidth) {
      setBasePrice(calculateCustomBasePrice(customLength, customWidth, customHeight))
      setLipPrice(calculateCustomLipPrice(customLength, customWidth))
      setIntermediatePrice(calculateCustomIntermediatePrice(customLength, customWidth))
      setMaterialPrice(calculateCustomBasePrice(customLength, customWidth, customHeight) * 0.8)
    }
  }, [customLength, customHeight, customWidth])

  useEffect(() => {
    if (selectedSize && selectedSize.title.includes('Standard')) {
      setCustomLength(selectedSize.length)
      setCustomWidth(selectedSize.width)
      setCustomHeight(selectedSize.height)
    }
  }, [selectedSize])

  useEffect(() => {
    const price = sum([
      basePrice,
      selectedUpperLip?.differentPrice ? lipPrice : 0,
      selectedIntermediateFloor?.differentPrice ? intermediatePrice : 0,
      selectedMaterial?.differentPrice ? materialPrice : 0
    ])
    setTotalPrice(price)
  }, [selectedSize, selectedUpperLip, selectedIntermediateFloor, selectedMaterial, basePrice, lipPrice, intermediatePrice, materialPrice])

  return (
    <div className="relative h-full">
      <div className="absolute top-0 inset-0 flex flex-col items-center m-4">
        <span className="font-bold text-xl">Rostiger Konfigurator</span>
        <span className="text-xs text-gray-400">Für Jules-Metall Schmiede</span>
        <button
          type="button"
          onClick={() => setOpenDemoImages(true)}
          className="z-20 mt-2 rounded-md bg-black bg-opacity-60 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          Beispiel Bilder
        </button>
      </div>
      <ExampleImagesPopup closeModal={() => setOpenDemoImages(false)} isOpen={openDemoImages} />
      <div className="w-full h-full">
        <Canvas flat linear>
          <ambientLight intensity={0.5} />
          <spotLight position={[5, 20, 20]} angle={0.15} intensity={0.3} />
          <Suspense fallback={null}>
            <Blumenkasten
              showUpperRand={selectedUpperLip?.title.toLocaleLowerCase()?.includes('mit') ? true : false}
              showIntermediateFloor={selectedIntermediateFloor?.title.toLocaleLowerCase()?.includes('mit') ? true : false}
              customHeight={customHeight}
              customLength={customLength}
              customWidth={customWidth}
            />
            <Stage environment={null}>
              <Environment path="/" files="potsdamer_platz_1k.hdr" />
            </Stage>
            <ContactShadows position={[0, -0.8, 0]} opacity={0.25} scale={10} blur={1.5} far={0.8} />
          </Suspense>
          <OrbitControls />
        </Canvas>
      </div>
      <div className="absolute bottom-0 inset-x-0 mt-10 m-4 md:w-1/2 xl:w-1/3 md:left-1/2 md:transform md:-translate-x-1/2">
        <div className="mt-2">
          {siteIndex == 0 && (
            <>
              <div className="flex items-center justify-between mb-2 mr-4">
                <h3 className="text-sm font-medium text-gray-900">Form auswählen</h3>
                <div className="flex flex-row items-center justify-center text-gray-500">{siteIndex + 1}/4</div>
              </div>
              <FormSelectionGroup
                label={'Form auswählen'}
                options={types}
                selected={selectedSize}
                setSelected={setSelectedSize}
                length={customLength}
                width={customWidth}
                height={customHeight}
                onChangeLength={setCustomLength}
                onChangeWidth={setCustomWidth}
                onChangeHeight={setCustomHeight}
              />
            </>
          )}
          {siteIndex == 1 && (
            <>
              <div className="flex items-center justify-between mb-2 mr-4">
                <h3 className="text-sm font-medium text-gray-900">Oberkante auswählen</h3>
                <div className="flex flex-row items-center justify-center text-gray-500">{siteIndex + 1}/4</div>
              </div>
              <OptionSelectionGroup
                customPrice={lipPrice}
                label={'Oberkante auswählen'}
                options={lips}
                selected={selectedUpperLip}
                setSelected={setSelectedUpperLip}
              />
            </>
          )}
          {siteIndex == 2 && (
            <>
              <div className="flex items-center justify-between mb-2 mr-4">
                <h3 className="text-sm font-medium text-gray-900">Zwischenboden auswählen</h3>
                <div className="flex flex-row items-center justify-center text-gray-500">{siteIndex + 1}/4</div>
              </div>
              <OptionSelectionGroup
                customPrice={intermediatePrice}
                label={'Zwischenboden auswählen'}
                options={intermediateFloors}
                selected={selectedIntermediateFloor}
                setSelected={setSelectedIntermediateFloor}
              />
            </>
          )}
          {siteIndex == 3 && (
            <>
              <div className="flex items-center justify-between mb-2 mr-4">
                <h3 className="text-sm font-medium text-gray-900">Material auswählen</h3>
                <div className="flex flex-row items-center justify-center text-gray-500">{siteIndex + 1}/4</div>
              </div>
              <OptionSelectionGroup
                customPrice={materialPrice}
                label={'Material auswählen'}
                options={materials}
                selected={selectedMaterial}
                setSelected={setSelectedMaterial}
              />
            </>
          )}
          {siteIndex == 4 && (
            <Overview
              calculatedEstimatedPrice={totalPrice}
              data={{
                length: customLength,
                width: customWidth,
                height: customHeight,
                basePrice: basePrice,
                withLip: selectedUpperLip?.differentPrice ? lipPrice : false,
                withIntermediate: selectedIntermediateFloor?.differentPrice ? intermediatePrice : false,
                material: selectedIntermediateFloor?.differentPrice ? intermediatePrice : 'normal'
              }}
            />
          )}
          <div className="flex flex-row space-x-4 items-center mt-4">
            {siteIndex > 0 && (
              <span onClick={() => setSiteIndex(siteIndex - 1)} className="cursor-pointer text-xs text-gray-600">
                zurück
              </span>
            )}
            {siteIndex != 4 && (
              <button
                type="submit"
                onClick={() => setSiteIndex(siteIndex + 1)}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-900 py-3 px-8 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                Weiter
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
