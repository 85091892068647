import { RadioGroup } from '@headlessui/react'

function CheckIcon(props) {
    return (
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        <path
          d="M7 13l3 3 7-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
}

export default function OptionSelectionGroup({customPrice, selected, setSelected, label, options}){
    return(
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
          <div className="space-y-2">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.title}
                value={option}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                      : ''
                  }
                  ${
                    checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white bg-opacity-75'
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="div"
                            className={`flex w-full flex-row justify-between font-medium  ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            <p>{option.title}</p>
                          </RadioGroup.Label>
                          <RadioGroup.Description
                                as="div"
                                className={`flex flex-col my-2 ${
                                checked ? 'text-sky-100' : 'text-gray-500'
                                }`}
                            >
                              { option.differentPrice && 
                                  <span>+ {customPrice.toFixed(0)} €</span>
                              }
                            </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
    );
}