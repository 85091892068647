import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
var validator = require("email-validator");
 
validator.validate("test@email.com");

export default function Overview({calculatedEstimatedPrice, data = undefined}){
    const [email, setEmail] = useState(null);
    const [adress, setAdress] = useState(null);
    const [details, setDetails] = useState(null);

    const checkIfAllElementsAreFilled = () => {
        return validator.validate(email) && adress && details;
    }

    return(
        <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md">
                <div className="flex flex-col items-center opacity-70">
                    <h2 className="text-center text-3xl font-bold text-gray-900">ca. {calculatedEstimatedPrice.toFixed(0)} €</h2>
                    <p className="text-center text-sm text-gray-900">
                        Jetzt unverbindlich anfragen und Angebot einholen    
                    </p>
                </div>
                <form className="space-y-6" action="https://api.web3forms.com/submit" method="POST">
                    <input type="hidden" name="access_key" value="aac79e56-e6cd-438f-9532-ace1a7fc65d6"/>
                    <input type="hidden" name="subject" value="Anfrage Rostkasten via Konfigurator" />
                    <input type="hidden" name="replyto" value={email}/>
                    <input type="hidden" name="remember" value="true" />
                    <input type="hidden" name="redirect" value="https://jules-metall.vercel.app/thankYou.html"/>
                    <input type="hidden" name="data" value={JSON.stringify(data)} required />
                    <div className="flex flex-col space-y-2 opacity-70">
                        <div>
                            <label className="text-xs font-bold">Ihre Email Adresse</label>
                            <input value={email} onChange={(e) => setEmail(e.currentTarget.value)} id="email-address" name="email" type="email" autoComplete="email" required className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-sky-900 focus:outline-none focus:ring-sky-900 sm:text-sm" placeholder="test@test.de"/>
                        </div>
                        <div>
                            <label className="text-xs font-bold">Ihre Adresse</label>
                            <input value={adress} onChange={(e) => setAdress(e.currentTarget.value)} id="address" name="adress" type="adress" autoComplete="adress" required className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-sky-900 focus:outline-none focus:ring-sky-900 sm:text-sm" placeholder="Musterstr. 11, 77777 Musterhausen"/>
                        </div>
                        <div>
                            <label className="text-xs font-bold">Details</label>
                            <textarea value={details} onChange={(e) => setDetails(e.currentTarget.value)} id="details" name="details" type="text" autoComplete="text" required className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-sky-900 focus:outline-none focus:ring-sky-900 sm:text-sm" placeholder="Details"/>
                        </div>
                    </div>
                    <div>
                        <button disabled={!checkIfAllElementsAreFilled()} type="submit" className="group relative disabled:opacity-50 flex w-full justify-center rounded-md border border-transparent bg-sky-900 py-3 px-8 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <PaperAirplaneIcon className="w-5 h-5"/>
                        </span>
                        Jetzt unverbindlich anfragen
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}