import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import ImageGallery from 'react-image-gallery'

const mainUrl = 'https://jules-metall.vercel.app'

const images = [
  {
    original: '/images/thumbnail/1.jpg',
    thumbnail: '/images/thumbnail/1.jpg'
  },
  {
    original: '/images/thumbnail/2.jpg',
    thumbnail: '/images/thumbnail/2.jpg'
  },
  {
    original: '/images/thumbnail/3.jpg',
    thumbnail: '/images/thumbnail/3.jpg'
  },
  {
    original: '/images/thumbnail/4.jpg',
    thumbnail: '/images/thumbnail/4.jpg'
  },
  {
    original: '/images/thumbnail/5.jpg',
    thumbnail: '/images/thumbnail/5.jpg'
  },
  {
    original: '/images/thumbnail/6.jpg',
    thumbnail: '/images/thumbnail/6.jpg'
  },
  {
    original: '/images/thumbnail/7.jpg',
    thumbnail: '/images/thumbnail/7.jpg'
  },
  {
    original: '/images/thumbnail/8.jpg',
    thumbnail: '/images/thumbnail/8.jpg'
  }
]

export default function ExampleImagesPopup({ isOpen, closeModal }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="div" className="flex flex-row items-center justify-between font-bold text-lg leading-6 text-gray-900">
                  <span>Beispiel Bilder</span>
                  <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer" />
                </Dialog.Title>
                <div className="mt-2">
                  <ImageGallery showFullscreenButton={false} lazyLoad items={images} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
