import {useState} from "react";
import { RadioGroup } from '@headlessui/react'
import { useScroll } from '@react-three/drei';
import InputField from './InputField';
import {ChevronDownIcon, ChevronRightIcon, PencilIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {calculateCustomBasePrice} from "./functions";

function CheckIcon(props) {
    return (
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        <path
          d="M7 13l3 3 7-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
}

export default function FormSelectionGroup({selected, setSelected, label, options, width, height, length, onChangeWidth, onChangeHeight, onChangeLength}){
    const [customInput, setCustomInput] = useState(false);

    return(
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
          <div className="flex flex-row space-x-2 overflow-scroll">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.title}
                value={option}
                style={{ minWidth: "10rem"}}
                className={({ active, checked }) =>
                  `${
                    checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white bg-opacity-75'
                  }
                    flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full justify-between">
                      <div className="flex">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="div"
                            className={`flex w-full flex-row justify-between font-medium  ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            <p>{option.title}</p>
                            {option.custom ?
                            <div className="flex flex-row items-center space-x-2">
                                { customInput ? 
                                    <XMarkIcon onClick={() => setCustomInput(!customInput)} className="w-5 h-5"/>
                                    :
                                    <PencilIcon onClick={() => setCustomInput(!customInput)} className="w-5 h-5"/>
                                }
                                {checked && (
                                  <div className="shrink-0 text-white">
                                    <CheckIcon className="h-6 w-6" />
                                  </div>
                                )}
                            </div> 
                            :
                            <>
                              {checked && (
                                <div className="shrink-0 text-white">
                                  <CheckIcon className="h-6 w-6" />
                                </div>
                              )}
                            </>
                            }
                          </RadioGroup.Label>
                          <RadioGroup.Description
                                as="div"
                                className={`flex flex-col my-2 ${
                                checked ? 'text-sky-100' : 'text-gray-500'
                                }`}
                            >
                                {option.custom ?
                                    <>
                                    { customInput ? 
                                        <div className={`flex flex-col${customInput ? "" : "hidden"}`}>
                                            <InputField title={"Länge"} onChangeValue={onChangeLength} value={length} placeholder={option.length}/>
                                            <InputField title={"Breite"} onChangeValue={onChangeWidth} value={width} placeholder={option.width}/>
                                            <InputField title={"Höhe"} onChangeValue={onChangeHeight} value={height} placeholder={option.height}/>
                                        </div>
                                    :
                                        <span className="text-xs">
                                            {length} x {width} x {height} cm <br/> (L B H)
                                        </span>
                                    }
                                    </>
                                    :
                                    <span className="text-xs">
                                    {option.length} x {option.width} x {option.height} cm <br/> (L B H)
                                    </span>
                                }
                                {option.custom  ? 
                                  <span className="font-bold mt-2">{calculateCustomBasePrice(length, width, height).toFixed(0)} €</span>
                                  :
                                  <span className="font-bold mt-2">{calculateCustomBasePrice(option.length, option.width, option.height).toFixed(0)} €</span>
                                }
                                
                            </RadioGroup.Description>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
    );
}