import { useEffect, useRef } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { TextureLoader } from 'three'

export default function Blumenkasten({ showUpperRand, showIntermediateFloor, customLength = 125, customWidth = 40, customHeight = 60 }) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  const { nodes } = useGLTF('blumenkasten.glb')
  const colorMapRust = useLoader(TextureLoader, 'rost_3.jpeg')

  // Animate model
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.z = -0.2 - (1 + Math.sin(t / 1.5)) / 20
    ref.current.rotation.x = Math.cos(t / 4) / 8
    ref.current.rotation.y = Math.sin(t / 4) / 8
    ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10
  })

  const xScaleFactor = customLength ? customLength / 125 : 1
  const yScaleFactor = customHeight ? customHeight / 60 : 1
  const zScaleFactor = customWidth ? customWidth / 40 : 1

  useFrame(() => {
    ref.current.rotation.z = 180
    ref.current.position.y = 1.5
  }, [])

  return (
    <group ref={ref} dispose={null} scale={0.5}>
      {showUpperRand == true && (
        <mesh geometry={nodes.UnterRand.geometry} position={[-2.68, -0.39, 0.65]} scale={[xScaleFactor, yScaleFactor, zScaleFactor]}>
          <meshStandardMaterial map={colorMapRust} />
        </mesh>
      )}
      {showIntermediateFloor == true && (
        <mesh geometry={nodes.Zwischenboden.geometry} position={[-2.67, 1.33, 0.64]} scale={[xScaleFactor, yScaleFactor, zScaleFactor]}>
          <meshStandardMaterial map={colorMapRust} />
        </mesh>
      )}
      <mesh geometry={nodes.Koerper.geometry} position={[-2.68, -0.38, 0.65]} scale={[xScaleFactor, yScaleFactor, zScaleFactor]}>
        <meshStandardMaterial map={colorMapRust} />
      </mesh>
    </group>
  )
}
